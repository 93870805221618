import Map from "../../../classes/Map"
import Marker from "./marker"
import Cluster from "./cluster"

const LIB_URL = [
    "https://unpkg.com/leaflet@1.0.1/dist/leaflet.js",
]

const CSS_URL = [
    "https://unpkg.com/leaflet@1.0.1/dist/leaflet.css",
    "https://unpkg.com/leaflet.markercluster@1.4.1/dist/MarkerCluster.css",
    "https://unpkg.com/leaflet.markercluster@1.4.1/dist/MarkerCluster.Default.css",
]

const DEPENDENT_URL = [
    "https://unpkg.com/leaflet.markercluster@1.4.1/dist/leaflet.markercluster.js",
]

export default class OsmMapAdapter extends Map {
    constructor(node, options) {
        super(node, options, { scripts: LIB_URL, styles: CSS_URL, dependent: DEPENDENT_URL })
    }

    _getBounds() {
        const bounds = this._map.getBounds()
        const se = bounds.getSouthEast()
        const nw = bounds.getNorthWest()
        return {
            lat1: nw.lat,
            lng1: nw.lng,
            lat2: se.lat,
            lng2: se.lng,
        }
    }

    _createMarker(options) {
        return new Marker(options, this._map)
    }

    _addMarker(options) {
        return this._createMarker(options).draw()
    }

    _removeMarker(marker) {
        marker.remove()
        return this
    }

    _setCenter(lat, lng) {
        let latitude = lat
        let longitude = lng

        if (lat instanceof Marker) {
            const position = lat.getPosition()
            latitude = position.lat
            longitude = position.lng
        } else if (typeof lat === "object" && typeof lng === "undefined") {
            latitude = lat.lat
            longitude = lat.lng
        }

        requestAnimationFrame(() => {
            this._map.panTo({ lat: latitude, lng: longitude })
        })
    }

    _setZoom(level) {
        this._map.setZoom(level)
    }

    _getCenter() {
        const bounds = this._map.getBounds()
        return bounds.getCenter()
    }

    _getZoom() {
        return this._map.getZoom()
    }

    _on(evt, fn) {
        this._map.on(evt, fn)
    }

    _off(evt, fn) {
        this._map.off(evt, fn)
    }

    _destroy() {
        try {
            this._map.off()
            this._map.remove()
        } catch (e) {
            // Do nothing
        }
        this._dom.innerHTML = ""
    }

    _createCluster(options) {
        return new Cluster(options, this._map)
    }

    _getDefaultOptions() {
        return {
            center: { lat: 0, lng: 0 },
            zoomControl: false,
            zoom: 2,
        }
    }

    _init() {
        this._map = window.L.map(this._dom, this._options)
        this._dom.getMap = () => this._map
        window.L.tileLayer("https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}", {
            attribution: "&copy; <a href=\"http://osm.org/copyright\">OpenStreetMap</a> contributors",
        }).addTo(this._map)
        return Promise.resolve()
    }
}
