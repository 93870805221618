import Marker from "../../../classes/Map/marker";

const BASE_IMAGES = "/images/markers/";

export default class OsmMapMarker extends Marker {
    constructor(options = {}, map) {
        super(options, map);
        this._marker = this._create();
    }

    draw() {
        this._marker.addTo(this._map);
        return this;
    }

    _getRaw() {
        return this._marker;
    }

    _on(evt, fn) {
        this._marker.on(evt, fn);
    }

    _off(evt, fn) {
        this._marker.on(evt, fn);
    }

    _remove() {
        this._map.removeLayer(this._marker);
    }

    _openWindow(content) {
        if (!this._marker.getPopup()) {
            this._marker.bindPopup("", { maxWidth: "none", closeOnClick: false, closeButton: false });
        }
        this._marker.setPopupContent(content);
        requestAnimationFrame(() => this._marker.openPopup());
    }

    _getPosition() {
        return this._options.position;
    }

    _closeWindow() {
        this._marker.closePopup();
    }

    _create() {
        const { lat, lng } = this._options.position;
        return window.L.marker([lat, lng], { icon: this._createIcon() });
    }

    _createIcon() {
        if (this._icon) {
            return this._icon;
        }

        return this._icon = window.L.icon({
            iconUrl: `${BASE_IMAGES}/point.svg`,
            iconSize: [21, 26]
        });
    }
}
