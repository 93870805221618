export default class Marker {
    constructor(options = {}, map) {
        this._options = options;
        this._data = options.data || {};
        this._map = map;
    }

    getData() {
        return this._data;
    }

    getOptions() {
        return this._options;
    }

    on(evt, fn) {
        this._on(evt, fn);
        return this;
    }

    off(evt, fn) {
        this._off(evt, fn);
        return this;
    }

    remove() {
        this._remove();
        return this;
    }

    openWindow(content) {
        this._openWindow(content);
        return this;
    }

    closeWindow() {
        this._closeWindow();
        return this;
    }

    getPosition() {
        return this._getPosition();
    }

    getRaw() {
        return this._getRaw();
    }

    _on() {
    /* eslint class-methods-use-this: "off" */
        throw new Error("Method on not implemented");
    }

    _off() {
    /* eslint class-methods-use-this: "off" */
        throw new Error("Method off not implemented");
    }

    _remove() {
    /* eslint class-methods-use-this: "off" */
        throw new Error("Method remove not implemented");
    }

    _create() {
    /* eslint class-methods-use-this: "off" */
        throw new Error("Method create not implemented");
    }

    _getPosition() {
    /* eslint class-methods-use-this: "off" */
        throw new Error("Method getPosition not implemented");
    }

    _getRaw() {
    /* eslint class-methods-use-this: "off" */
        throw new Error("Method getRaw not implemented");
    }
}
