export default class Cluster {
    addMarkers(...args) {
        this._addMarkers(...args);
    }

    clear() {
        this._clear();
    }

    remove() {
        this._remove();
    }
}
