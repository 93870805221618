import GoogleMapAdapter from "@/services/Map/google";
import OsmMapAdapter from "@/services/Map/osm";

export const MAP_TYPES = {
    google: GoogleMapAdapter,
    osm: OsmMapAdapter
};

export const createMap = (adapter, el, config) => {
    return new adapter(el, config);
};
