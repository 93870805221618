import Cluster from "../../../classes/Map/cluster";

export default class GoggleMapCluster extends Cluster {
    constructor(options = {}, map) {
        super();
        this._cluster = new window.MarkerClusterer(map, []);
        this._map = map;
        this._options = options;
    }

    _addMarkers(value) {
        const markers = Array.isArray(value) ? value : [value];
        this._cluster.addMarkers(markers.map(m => m.getRaw()));
    }

    _clear() {
        try {
            this._cluster.clearMarkers();
        } catch (e) {
            // Do nothing
        }
    }
}
