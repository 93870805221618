import ResourceLoader from "resource-loader";

export default class Map {
    constructor(node, options = {}, resources = {}) {
        this._options = options;
        this._dom = node;
        this._map = null;
        this._resources = resources;
    }

    getBounds() {
        return this._getBounds();
    }

    getZoom() {
        return this._getZoom();
    }

    setZoom(level) {
        this._setZoom(level);
        return this;
    }

    setCenter(lat, lng) {
        this._setCenter(lat, lng);
        return this;
    }

    getCenter(lat, lng) {
        return this._getCenter(lat, lng);
    }

    on(evt, fn) {
        this._on(evt, fn);
        return this;
    }

    off(evt, fn) {
        this._off(evt, fn);
        return this;
    }

    createMarker(options) {
        return this._createMarker(options);
    }

    addMarker(options = {}) {
        return this._addMarker(options);
    }

    removeMarker(marker) {
        return this._removeMarker(marker);
    }

    createCluster() {
        return this._createCluster();
    }

    destroy() {
        return this._destroy();
    }

    getDefaultOptions() {
        return this._getDefaultOptions();
    }

    init() {
        if (this._map) {
            return Promise.resolve(this);
        }

        if (!this._dom) {
            throw new Error("Not specified dom item");
        }

        let { scripts, styles, dependent } = this._resources;
        if (Array.isArray(this._resources)) {
            scripts = this._resources;
        }

        return Promise.all([
            ResourceLoader.loadScript(scripts || []).then(() => ResourceLoader.loadScript(dependent || [])),
            ResourceLoader.loadStyle(styles || [])
        ]).then(() => this._init());
    }

    _getBounds() {
    /* eslint class-methods-use-this: "off" */
        throw new Error("Method getBounds not implemented");
    }

    _getZoom() {
    /* eslint class-methods-use-this: "off" */
        throw new Error("Method getZoom not implemented");
    }

    _setCenter() {
    /* eslint class-methods-use-this: "off" */
        throw new Error("Method setCenter not implemented");
    }

    _setZoom() {
    /* eslint class-methods-use-this: "off" */
        throw new Error("Method setZoom not implemented");
    }

    _getCenter() {
    /* eslint class-methods-use-this: "off" */
        throw new Error("Method getCenter not implemented");
    }

    _on() {
    /* eslint class-methods-use-this: "off" */
        throw new Error("Method on not implemented");
    }

    _off() {
    /* eslint class-methods-use-this: "off" */
        throw new Error("Method off not implemented");
    }

    _init() {
    /* eslint class-methods-use-this: "off" */
        throw new Error("Method init not implemented");
    }

    _addMarker() {
    /* eslint class-methods-use-this: "off" */
        throw new Error("Method addMarker not implemented");
    }

    _removeMarker() {
    /* eslint class-methods-use-this: "off" */
        throw new Error("Method removeMarker not implemented");
    }

    _createCluster() {
    /* eslint class-methods-use-this: "off" */
        throw new Error("Method createCluster not implemented");
    }

    _getDefaultOptions() {
        return {};
    }

    _destroy() {
    /* eslint class-methods-use-this: "off" */
        throw new Error("Method destroy not implemented");
    }
}
