import Map from "../../../classes/Map";
import Marker from "./marker";
import Cluster from "./cluster";

const LIB_URL = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCrGaroYIOPAu9IakE6gEzY2sa5t23mCpQ&amp;language=ru&amp";
const CLUSTER_URL = "https://cdnjs.cloudflare.com/ajax/libs/js-marker-clusterer/1.0.0/markerclusterer_compiled.js";

export default class GoogleMapAdapter extends Map {
    constructor(node, options) {
        super(node, options, [LIB_URL, CLUSTER_URL]);
    }

    _getBounds() {
        const bounds = this._map.getBounds().toJSON();

        return {
            lat1: bounds.north,
            lng1: bounds.west,

            lat2: bounds.south,
            lng2: bounds.east
        };
    }

    _createMarker(options) {
        return new Marker(options, this._map);
    }

    _addMarker(options) {
        return this._createMarker(options).draw();
    }

    _removeMarker(marker) {
        marker.remove();
        return this;
    }

    _setCenter(lat, lng) {
        let latitude;
        let longitude;
        if (lat instanceof Marker) {
            const position = lat.getPosition();
            latitude = position.lat;
            longitude = position.lng;
        } else {
            latitude = lat;
            longitude = lng;
        }
        this._map.setCenter({ lat: latitude, lng: longitude });
    }

    _setZoom(level) {
        this._map.setZoom(level);
    }

    _getCenter() {
        return this._map.getCenter();
    }

    _getZoom() {
        return this._map.getZoom();
    }

    _on(evt, fn) {
        this._map.addListener(evt, fn);
    }

    _off(evt, fn) {
        this._map.removeListener(evt, fn);
    }

    _destroy() {
        window.google.maps.event.clearInstanceListeners(this._map.getDiv());
        this._dom.innerHTML = "";
    }

    _createCluster(options) {
        return new Cluster(options, this._map);
    }

    _getDefaultOptions() {
        return {
            center: { lat: 0, lng: 0 },
            zoom: 2,
            streetViewControl: false,
            disableDefaultUI: true,
            mapTypeControl: false
        };
    }

    _init() {
        const opts = Object.assign({}, this._options, { clickableIcons: false });
        this._map = new window.google.maps.Map(this._dom, opts);
        return new Promise((resolve) => {
            window.google.maps.event.addListenerOnce(this._map, "idle", () => resolve());
        });
    }
}
