<template>
    <div :class="{'map-location-control__invalid': !valid && validations[0]}">

        <div class="v-messages theme--light error--text mb-2" v-if="!valid && validations[0]">
            <div class="v-messages__wrapper">
                <div v-for="(message, i) in validations" :key="i"
                     class="v-messages__message message-transition-enter-to">
                    {{message}}
                </div>
            </div>
        </div>

        <Map @map:click="onClickMap" :config="config" @init="onInitMap()" ref="map"/>
    </div>
</template>
<script>
import Map from "../components/Map"
import Validatable from "vuetify/lib/mixins/validatable"

const toValue = (asModel, model) => {
    if (asModel) {
        return { Lat: model.lat, Lon: model.lng }
    }
    return model
}

const toModel = (asModel, value) => {
    if (asModel && value) {
        return { lat: value.Lat, lng: value.Lon }
    }
    return value
}

const isEmpty = (val = {}) => typeof val.lat !== "number" || typeof val.lng !== "number" || (val.lat === 0 && val.lng === 0)

export default {
    components: { Map },
    mixins: [Validatable],
    props: {
        value: {
            type: Object,
            default() {
                return toModel(this.asModel, this.value)
            },
        },
        rules: Array,
        asModel: {
            type: Boolean,
            default: false,
        },
        zoom: {
            type: Number,
            default: 12,
        },
        config: {
            type: Object,
            default() {
                const center = toModel(this.asModel, this.value)
                return {
                    center: Object.assign({}, center, { lat: 0, lng: 0 }),
                    zoomControl: false,
                    zoom: isEmpty(center) ? 2 : this.zoom,
                }
            },
        },
    },

    created() {
        ["value.lat", "value.lng", "value.Lat", "value.Lon"].forEach(n => this.$watch(n, this.onUpdateLocation))
        if (this.$parent.register) {
            this.$parent.register(this)
        }
    },

    methods: {
        onUpdateLocation() {
            const latlng = toModel(this.asModel, this.value)
            this.$marker && this.$marker.remove()

            if (!isEmpty(latlng) && this.$refs.map) {
                this.$marker = this.$refs.map.addMarker({ position: latlng })
                this.$refs.map.setCenter(this.$marker)
                this.$emit("input", toValue(this.asModel, latlng))
            } else {
                this.$marker = null
            }
        },

        onClickMap({ latlng: { lat, lng } }) {
            this.$emit("input", toValue(this.asModel, { lat, lng }))
        },

        onInitMap() {
            this.onUpdateLocation(this.value)
        },

        focusTo(lat, lng) {
            this.$refs.map.setCenter({ lat, lng })
            this.$refs.map.setZoom(12)
        },
    },
}
</script>

<style>
    .map-location-control__invalid .map {
        border: 1px solid #ff5252;
        box-shadow: 0px 0px 4px #ff5252;
    }
</style>
