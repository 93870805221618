import Marker from "../../../classes/Map/marker";

const BASE_IMAGES = "/images/markers/";

const createImagePath = (name) => `${BASE_IMAGES}${name}.svg`;
const transformOptions = (value) => {
    if (value.icon) {
        value.icon = createImagePath(value.icon);
    }
    return value;
};


function createPopupClass() {
    function Popup(position, content) {
        this.position = position;

        content.classList.add("popup-bubble");

        const bubbleAnchor = document.createElement("div");
        bubbleAnchor.classList.add("popup-bubble-anchor");
        bubbleAnchor.appendChild(content);

        // This zero-height div is positioned at the bottom of the tip.
        this.containerDiv = document.createElement("div");
        this.containerDiv.classList.add("popup-container");
        this.containerDiv.appendChild(bubbleAnchor);

        // Optionally stop clicks, etc., from bubbling up to the map.
        window.google.maps.OverlayView.preventMapHitsAndGesturesFrom(this.containerDiv);
    }

    // ES5 magic to extend google.maps.OverlayView.
    Popup.prototype = Object.create(window.google.maps.OverlayView.prototype);

    /** Called when the popup is added to the map. */
    Popup.prototype.onAdd = function() {
        this.getPanes().floatPane.appendChild(this.containerDiv);
    };

    /** Called when the popup is removed from the map. */
    Popup.prototype.onRemove = function() {
        if (this.containerDiv.parentElement) {
            this.containerDiv.parentElement.removeChild(this.containerDiv);
        }
    };

    /** Called each frame when the popup needs to draw itself. */
    Popup.prototype.draw = function() {
        const divPosition = this.getProjection().fromLatLngToDivPixel(this.position);

        // Hide the popup when it is far out of view.
        const display =
            Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ?
                "block" :
                "none";

        if (display === "block") {
            this.containerDiv.style.left = divPosition.x + "px";
            this.containerDiv.style.top = divPosition.y + "px";
        }
        if (this.containerDiv.style.display !== display) {
            this.containerDiv.style.display = display;
        }
    };

    return Popup;
}

export default class GoogleMapMarker extends Marker {
    constructor(options = {}, map) {
        super(options, map);
        this._marker = this._create();
    }

    draw() {
        this._marker.setMap(this._map);
        return this;
    }

    _getRaw() {
        return this._marker;
    }

    _on(evt, fn) {
        this._marker.addListener(evt, fn);
    }

    _off(evt, fn) {
        this._marker.removeListener(evt, fn);
    }

    _remove() {
        this._marker.setMap(null);
    }

    _openWindow(content) {
        const Popup = this._popup || (this._popup = createPopupClass());
        this._window = new Popup(this._marker.getPosition(), content);
        this._window.setMap(this._map);
    }

    _getPosition() {
        return this._marker.getPosition().toJSON();
    }

    _closeWindow() {
        this._window && this._window.setMap(null);
    }

    _create() {
        const options = transformOptions(Object.assign({}, this._options));
        return new window.google.maps.Marker(options);
    }
}
