import Cluster from "../../../classes/Map/cluster";

export default class OsmMapCluster extends Cluster {
    constructor(options = {}, map) {
        super();
        this._markers = [];
        this._map = map;
        this._options = options;
    }

    _addMarkers(value) {
        const markers = Array.isArray(value) ? value : [value];
        this._markers.push(...markers);
        this._clear();
        this._create();
    }

    _create() {
        this._cluster = window.L.markerClusterGroup();
        this._markers.forEach(m => this._cluster.addLayer(m.getRaw()));
        this._map.addLayer(this._cluster);
    }

    _clear() {
        try {
            this._map.removeLayer(this._cluster);
        } catch (e) {
            // Do nothing
        }
    }
}
