<template>
    <div class="map"></div>
</template>

<script>
import { createMap, MAP_TYPES } from "@/services/Map";

export default {
    name: "Map",
    props: {
        config: {
            type: Object,
            default: () => ({})
        },
        type: {
            type: String,
            default: () => 'osm'
        }
    },

    mounted() {
        this.map = createMap(MAP_TYPES[this.type], this.$el, this.config);
        this.init();
    },

    destroyed() {
        this.map.destroy();
    },

    methods: {
        getBounds() {
            return this.map.getBounds();
        },

        getZoom() {
            return this.map.getZoom();
        },

        setZoom(level) {
            return this.map.setZoom(level);
        },

        setCenter(lat, lng) {
            this.map.setCenter(lat, lng);
            return this;
        },

        getCenter(lat, lng) {
            return this.map.getCenter(lat, lng);
        },

        on(evt, fn) {
            this.map.on(evt, fn);
            return this;
        },

        off(evt, fn) {
            this.map.off(evt, fn);
            return this;
        },

        addMarker(options = {}) {
            return this.map.addMarker(options);
        },

        createMarker(options = {}) {
            return this.map.createMarker(options);
        },

        removeMarker(marker) {
            return this.map.removeMarker(marker);
        },

        createCluster() {
            return this.map.createCluster();
        },

        getDefaultOptions() {
            return this.map.getDefaultOptions();
        },

        init() {
            return this.map.init().then(() => {
                this.$emit('init');
                this.addListeners();
            });
        },

        addListeners() {
            const keys = Object.keys(this.$listeners).filter(key => key.startsWith('map:'));
            keys.forEach((key) => {
                const listener = this.$listeners[key];
                this.on(key.replace('map:', ''), listener)
            })
        },

        destroy() {
            return this.map.destroy();
        }
    }
};
</script>
<style>
    .map {
        width: 100%;
        height: 100%;
    }

    /* Google map popup style */
    .popup-bubble {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-50%, -100%);
        background-color: white;
        border-radius: 2px;
        font-family: sans-serif;
        overflow-y: auto;
        box-shadow: 0 2px 4px rgba(92, 105, 121, 0.471666);
    }

    .popup-bubble-anchor {
        position: absolute;
        width: 100%;
        bottom: 8px;
        left: 0;
    }

    .popup-bubble-anchor::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-50%, 0);
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 8px solid white;
    }

    .popup-container {
        cursor: auto;
        height: 0;
        position: absolute;
    }

    /* leaflet popup style */
    .leaflet-popup .leaflet-popup-content-wrapper {
        border-radius: 2px;
        font-family: sans-serif;
        overflow-y: auto;
        box-shadow: 0 2px 4px rgba(92, 105, 121, 0.471666);
    }

    .leaflet-popup a {

    }
</style>
